<template>
  <div>
    <div class="d-flex flex-row mb-6">
      <p class="text-2xl mb-6">Google Analytics par site avec filtres</p>

      <v-autocomplete
        outlined
        dense
        v-model="departmentShortnameSelected"
        :items="departmentList"
        :menu-props="{ maxHeight: '400', zIndex: 13 }"
        label="Département"
        item-text="name"
        item-value="shortname"
        class="select-department ml-16 shrink"
      />
      <!-- @change="setDepartment" -->

      <v-autocomplete
        outlined
        dense
        v-model="frequencySelected"
        :items="frequencyList"
        :menu-props="{ maxHeight: '400', zIndex: 13 }"
        label="Périodicité"
        item-text="name"
        item-value="id"
        class="select-frequency ml-4 shrink"
        @change="setFrequency"
      />

      <!-- datePickerSingleDaySelect(this.identifier, this.date) -->
      <date-picker-single-day
        v-if="frequencySelected == 2"
        identifier="dayFilter"
        dateInit="yesterday"
        @onSelect="setSelectedDay"
        dense="dense"
        customClass="ml-4"
        hint=""
      />
      <date-picker-single-month
        v-else-if="frequencySelected == 5"
        identifier="dayFilter"
        dateInit="previous"
        @onSelect="setSelectedMonth"
        dense="dense"
        customClass="ml-4"
        hint=""
      />
      <v-autocomplete
        v-else
        outlined
        dense
        v-model="dateSelected"
        :items="dateList"
        :menu-props="{ maxHeight: '400', zIndex: 13 }"
        label="Date"
        item-text="date"
        item-value="date"
        class="select-date ml-4 shrink"
        @change="getData"
      />
    </div>

    <v-card class="container">
      <v-card-text>
        <div class="d-flex flex-row mb-6">
          <v-text-field
            v-model="searchStr"
            label="Search"
            @input="search"
            dense
          />
          <v-autocomplete
            outlined
            dense
            v-model="filterIdSelected"
            :items="filterList"
            :menu-props="{ maxHeight: '400', zIndex: 13 }"
            label="Filtre"
            item-text="name"
            item-value="id"
            class="select-filter ml-4 shrink"
            @change="getData"
          />

          <v-spacer></v-spacer>
          <dot-menu
            @exportCsv="exportCSV"
            :csvButton="{
              csvData,
              filename: csvFilename,
            }"
          />
        </div>
        <div v-if="filteredData.length !== 0">
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-center">Nom du site (id)</th>
                  <th class="text-center">Domaine</th>
                  <th class="text-center">Sessions</th>
                  <th class="text-center">Views</th>
                  <th class="text-center">Views / Session</th>
                  <th class="text-center">
                    GA propertyID
                    <!-- <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <span
                          v-bind="attrs"
                          v-on="on"
                          v-html="`*<sup>?</sup>`"
                        ></span>
                      </template>
                      <span>Un GA viewID spécifique est utilisé</span>
                    </v-tooltip> -->
                  </th>
                  <th class="text-center">Sample ratio</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in filteredData" :key="item.id">
                  <td class="text-left">
                    <b class="primary--text">
                      {{ item.site_name }} ({{ item.site_id }})
                    </b>
                  </td>
                  <td class="text-left">{{ item.site_domain }}</td>
                  <td class="text-right">
                    <b>{{ item.sessions }}</b>
                  </td>
                  <td class="text-right">{{ item.views }}</td>
                  <td class="text-right">
                    {{ item.views_by_session }}
                  </td>
                  <td class="text-right">
                    {{ item.ga_view_id }}
                    <span v-if="item.department_id">*</span>
                  </td>
                  <td class="text-right">
                    {{ item.sample_ratio ? `${item.sample_ratio}%` : "100%" }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
        <div v-else>
          {{ noDataMessage }}
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import router from "@/router";
import axios from "@axios";

import DotMenu from "@/components/common/menus/DotMenu";
import DatePickerSingleDay from "@/components/common/filters/DatePickerSingleDay";
import DatePickerSingleMonth from "@/components/common/filters/DatePickerSingleMonth";
import { dataLoadingText } from "@/utils/constants";
import { saveRmraView } from "@/utils/rmr-analytics";

export default {
  name: "GaSitesByFilter",
  props: {
    departmentShortnameSelected: String,
  },
  components: {
    DatePickerSingleDay,
    DatePickerSingleMonth,
    DotMenu,
  },
  watch: {
    departmentShortnameSelected: {
      // the callback will be called immediately after the start of the observation
      immediate: true,
      handler(val, oldVal) {
        if (typeof oldVal != "undefined") {
          router.push({ name: `ga-sites-by-filter-${val}` });
          this.getData();
        }
      },
    },
  },
  created() {
    this.$store.dispatch("common/getDepartmentList");

    let previousMondayDate = this.getPreviousMonday();
    this.previousMondayStr = this.dateToStr(previousMondayDate);
    let previousMondays = this.getPreviousMondays(previousMondayDate, 10);
    previousMondays = previousMondays.map((x) => {
      return { date: x };
    });
    this.weeklyDateList = previousMondays;

    let previousFirstOfMonthDate = this.getPreviousFirstOfMonth();
    this.previousFirstOfMonthStr = this.dateToStr(previousFirstOfMonthDate);
    let previousFirstOfMonthList = this.getPreviousFirstOfMonthList(
      previousFirstOfMonthDate,
      10
    );
    previousFirstOfMonthList = previousFirstOfMonthList.map((x) => {
      return { date: x };
    });
    this.monthlyDateList = previousFirstOfMonthList;
    this.getData();
  },
  mounted() {
    saveRmraView(this.$route);
  },
  data() {
    return {
      componentKey: 0,
      usePrettyNumbers: true,
      departmentIdSelected: null,
      frequencyList: [
        { id: 2, name: "daily" },
        { id: 3, name: "weekly" },
        { id: 5, name: "monthly" },
      ],
      filterList: [
        { id: 1, name: "Medium = EMAIL" },
        { id: 2, name: "Source = WPN" },
        { id: 3, name: "Source = EMAIL" },
      ],
      filterIdSelected: 1,
      frequencySelected: 2,
      previousDayStr: null,
      weeklyDateList: [],
      previousMondayStr: null,
      monthlyDateList: [],
      previousFirstOfMonthStr: null,
      searchStr: "",
      searchTimeout: null,
      noDataMessage: dataLoadingText,
      data: [
        /* {
          "department_id": 3,
          "frequency": 2,
          "date": "2022-10-20",
          "site_id": 39,
          "site_name": "Melty",
          "site_domain": "www.melty.fr",
          "ga_view_id": 117958263,
          "sessions": 105,
          "views": 462,
          "sample_ratio": 100,
          "dimension_hostname_id": 30,
          "hostname": "www.melty.fr",
          "dimension_medium_id": 6,
          "medium": "email",
          "dimension_segment_id": 1,
          "segment": "not_amp",
          "dimension_source_id": 9,
          "source": "EMAIL",
          "dimension_campaign_id": 937,
          "campaign": "NL_Telestar_21062022"
        },... */
      ],
      csvData: "",
      csvFilename: "GA-export.csv",
      filteredData: [],
    };
  },
  methods: {
    async exportCSV() {
      if (!this.frequencySelected || !this.dateSelected) {
        alert("missing params");
        return false;
      }
      const url = "/ga_source_mediums/filtered-data/export";
      const queryParams = {
        frequency: this.frequencySelected,
        date: this.dateSelected,
        filter_id: this.filterIdSelected,
      };
      // router.push({ path: '/register', query: { plan: 'private' } })
      const { data } = await axios.get(url, { params: queryParams });
      const frequencyNameById = {
        2: "daily",
        3: "weekly",
        5: "monthly",
      };
      const filterNameById = {
        1: "email",
        2: "wpn",
      };
      const frequencyName = frequencyNameById[this.frequencySelected];
      const filterName = filterNameById[this.filterIdSelected];
      this.csvFilename = `GA-export-${frequencyName}-${this.dateSelected}-filter-${filterName}.csv`;
      this.csvData = data;
    },
    setSelectedDay(day) {
      this.dateSelected = day;
      this.getData();
    },
    setSelectedMonth(month) {
      this.dateSelected = `${month}-01`;
      this.getData();
    },
    prettyNbs(x, sep = " ") {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, sep);
    },
    setDepartment() {
      if (this.$route.path !== `ga-sites-${this.departmentShortnameSelected}`) {
        router.push({ name: `ga-sites-${this.departmentShortnameSelected}` });
        this.getData();
      }
    },
    setFrequency() {
      if (this.frequencySelected == 5) {
        this.dateList = this.monthlyDateList;
        this.dateSelected = this.previousFirstOfMonthStr;
      } else if (this.frequencySelected == 3) {
        this.dateList = this.weeklyDateList;
        this.dateSelected = this.previousMondayStr;
      } else {
        // this.dateList = this.dailyDateList;
        // this.dateSelected = this.previousDayStr;
      }
      this.getData();
    },

    async getData() {
      this.data = [];
      this.filteredData = [];
      this.noDataMessage = dataLoadingText;
      this.departmentIdSelected = 3; // crm
      // let propsDepartmentShortname = this.departmentShortnameSelected;
      //this.departmentIdSelected = this.departmentList.filter(function (el) {return el.shortname == propsDepartmentShortname;})[0]["id"];
      if (!this.frequencySelected || !this.dateSelected) {
        return false;
      }
      // let url = `/ga_sites/filtered/3/5/2022-08-08`; // 3 is the CRM department id, 5 is monthly
      const url = "/ga_source_mediums/filtered-data";
      const queryParams = {
        frequency: this.frequencySelected,
        date: this.dateSelected,
        filter_id: this.filterIdSelected,
      };

      const {
        data: { error, items },
      } = await axios.get(url, { params: queryParams });
      if (error !== undefined) {
        this.noDataMessage = error;
        return;
      }
      // JSON.parse(JSON.stringify(response.data));
      this.data = items;
      this.setFilteredData();
    },
    search() {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
        this.searchTimeout = null;
      }
      setTimeout(() => {
        this.setFilteredData();
      }, 500);
    },
    setFilteredData() {
      const filteredData = [];
      const searchStrLC = this.searchStr.toLowerCase();
      for (let i = 0; i < this.data.length; i++) {
        if (
          this.searchStr == "" ||
          this.data[i]["site_name"].toLowerCase().includes(searchStrLC) ||
          this.data[i]["site_domain"].toLowerCase().includes(searchStrLC)
        ) {
          if (this.usePrettyNumbers) {
            this.data[i]["views"] = this.prettyNbs(this.data[i]["views"]);
            this.data[i]["sessions"] = this.prettyNbs(this.data[i]["sessions"]);
          }
          filteredData.push(this.data[i]);
        }
      }
      this.filteredData = filteredData;
    },
    dateToStr(date) {
      return date.toISOString().split("T")[0];
    },
    getPreviousMonday() {
      const date = new Date();
      const day = date.getDay() || 7;
      if (day !== 1) date.setHours(-24 * (day - 1));
      date.setHours(date.getHours() + 25);
      date.setHours(-24 * 7);
      return date;
    },
    getPreviousMondays(previousMondayDate, n) {
      const list = [];
      const d = new Date(previousMondayDate.getTime());
      for (let i = 0; i < n; i++) {
        list.push(this.dateToStr(d));
        d.setHours(-24 * 7);
      }
      return list;
    },
    getPreviousFirstOfMonth() {
      let date = new Date();
      date = new Date(date.getFullYear(), date.getMonth() - 1, 2);
      date.setHours(-5);
      return date;
    },
    getPreviousFirstOfMonthList(previousFirstOfMonthDate, n) {
      const list = [];
      const d = new Date(previousFirstOfMonthDate.getTime());
      for (let i = 0; i < n; i++) {
        list.push(this.dateToStr(d));
        d.setMonth(d.getMonth() - 1);
      }
      return list;
    },
  },
  computed: {
    departmentList() {
      return this.$store.getters["common/getDepartmentList"];
    },
  },
};
</script>

<style scoped></style>

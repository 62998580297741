<template>
  <div>
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="formattedDate"
          label="Date"
          persistent-hint
          :prepend-icon="icons.mdiCalendar"
          readonly
          v-bind="attrs"
          v-on="on"
          :dense="dense"
          :outlined="outlined"
          :class="customClass"
          :hint="hint"
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="date"
        no-title
        @input="menu = false"
      ></v-date-picker>
    </v-menu>
  </div>
</template>

<script>
import { mdiCalendar } from "@mdi/js";
import { computed, onMounted, ref, watch } from "@vue/composition-api";

export default {
  name: "DatePickerSingleDay",
  props: {
    identifier: {
      type: String,
      default: "date-picker-single-day",
    },
    dateInit: {
      type: String,
      default: () => {
        return "yesterday"; // one of
      },
      validator(value) {
        return ["null", "yesterday", "late-yesterday", "n-days-ago"].includes(
          value
        );
      },
    },
    nDays: {
      type: String,
      default: () => {
        return "1"; // one of
      },
      validator(value) {
        let intValue = parseInt(value);
        return intValue == value && value >= 1;
      },
    },
    dense: {
      type: String,
      default: null,
    },
    outlined: {
      type: String,
      default: null,
    },
    customClass: {
      type: String,
      default: null,
    },
    hint: {
      type: String,
      default: "Format DD/MM/YYYY",
    },
  },
  emits: ["onSelect"],
  setup(props, { emit }) {
    const date = ref("");
    const menu = ref(false);

    // =>  METHODS
    const formatDate = (_date) => {
      if (!_date) {
        return null;
      }

      const [year, month, day] = _date.split("-");

      return `${day}/${month}/${year}`;
    };

    // =>  COMPUTED
    const formattedDate = computed(() => formatDate(date.value));

    // =>  WATCH
    watch(
      () => date.value,
      () => {
        emit("onSelect", date.value, props.identifier);
      }
    );

    // =>  HOOKS
    onMounted(() => {
      if (props.dateInit == "null") {
        return;
      }

      const d = new Date();
      const previousDay = new Date(d.getTime());
      if (props.dateInit == "yesterday") {
        previousDay.setDate(d.getDate() - 1);
      } else if (props.dateInit == "n-days-ago") {
        previousDay.setDate(d.getDate() - parseInt(props.nDays));
      } else if (props.dateInit == "late-yesterday") {
        previousDay.setDate(d.getDate() - 2);
      }
      date.value = new Date(
        previousDay - previousDay.getTimezoneOffset() * 60000
      )
        .toISOString()
        .substring(0, 10);
    });

    // =>  DATA
    return {
      date,
      formattedDate,
      menu,
      icons: {
        mdiCalendar,
      },
    };
  },
};
</script>

<style scoped>
/* 
[role="menu"] {
  top: 215px !important;
  right: 0px !important; 
}*/
</style>
